@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=League+Spartan&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Nunito:wght@400;700&family=Poppins:wght@400;700&family=Raleway:wght@400;700&display=swap');



body {
  font-family: 'Work Sans', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #6746AE;

}

.companyLogo{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.companyLogo>.logo{
  padding: 20px;
  margin: 20px;
  background-image: url('../public/images/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.navigation ul {
  display: flex; 
  justify-content: flex-end;
  list-style-type: none; 
  padding: 20px; 

}

.navigation ul li {
  color: white;
  font-size: 1.7rem;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 4px;
  margin-right: 20px;
}

.navigation ul li:hover{
  border-bottom: 1px solid white; 
  backdrop-filter: blur(4px); 
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.link{
  text-decoration: none;
  cursor: pointer;

}

main{
  position: relative;
  background: #5A068D;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Fredoka One', cursive;
  height: 88vh;
}

.welcome{
  color: white;
  font-size: 8rem;
  margin-top: 10px;
}

.bookmark{
  background-image: url('../public/images/bookmark.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  margin-right: 20px;
  width: 160px;
  height: 270px;
  top: 0;
  right: 0;
}

.bitContainer{
  margin-left: 30px;
}


.bits{
  color: white;
  font-family: 'Fredoka One', cursive;
  font-size: 6rem;
  font-weight: 400;
  line-height: 1;
  word-wrap: break-word;
}

.byteQueens{
  color: white;
  font-size: 9rem;
  margin-top: 20px;
  margin-bottom: 100px;
}


.applyContainer{
  margin-top: 10px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.title{
  font-family: 'Fredoka One', cursive;
  font-size: 5rem;
  color: white;
}

.paragraph{
  font-family: 'Work Sans', sans-serif;
  font-size: 4rem;
  font-weight: 400;
  color: white;
  text-align: center;
}

.apply{
  cursor: pointer;
}

.applyButton{
  background: white;
  border: none;
  border-radius: 50px;
  font-family: 'Fredoka One', cursive;
  font-size: 3rem;
  width: 400px;
  height: 100px;
  cursor: pointer;

}

.apply:hover{
  transform: scale(1.1);
}



.contactContainer{
  padding: 30px;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-size: 4rem;

}

.computer {
  margin-top: 50px;
  width: 300px;
  height: 200px;
  background-color:white;
  border-radius: 15px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 80px;
}

.screen {
  width: 100%;
  height: 90%;
  background-color: #5A068D;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3rem;
}

.base {
  width: 60%;
  height: 10px;
  background-color: white;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.keyboard {
  width: 80%;
  height: 10px;
  background-color: white;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}


section.aboutTeam{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

main.aboutus {
  height: 80vh;
  background-color: white;
  margin-bottom: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  padding: 20px;
}

.aboutContainer {
  margin-top: 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;  
}

.cardPicture {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ceoCard {
  width: 80%;
  height: auto;
  border-radius: 5%;
  z-index: 1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.ceoCard:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.aboutDetail {
  padding-left: 20px;
  max-width: 600px;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  width: 120%;
}

.question {
  font-size: 3rem;
  color: #2c3e50;
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.answer {
  text-align: center;
  margin-top: 20px;
  background-color: #ecf0f1;
  box-sizing: border-box;
  margin: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'League Spartan', sans-serif;
  font-size: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ans {
  padding: 10px;
  color: #2c3e50;
  width: auto;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'League Spartan', sans-serif;
}

.aim{
  padding-top: 20px;
  font-size: 1.2rem;
}

.aboutTitle {
  font-size: 3rem;
  color: #6846ae;
  padding-top: 20px; 
  padding-bottom: 20px;
  font-family: 'Arial, sans-serif';
  text-align: center; 
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); 
  margin: 20px 0; 
}


.details {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #2c3e50;
  gap: 2rem;
  margin-bottom: 30px;
}

.mentorship, .competitions, .communities, .jobs, .resources {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'League Spartan', sans-serif;
  width: auto;
  margin: 10px;
  font-size: 1rem;
  text-align: center;
}

.handshake, .trophy, .community, .job, .resource {
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  transition: transform 0.3s;
}

.handshake:hover, .trophy:hover, .community:hover, .job:hover, .resource:hover {
  transform: scale(1.1);
}

.handshake {
  background-image: url('../public/images/handshake.svg');
}

.trophy {
  background-image: url('../public/images/trophy.svg');
}

.community {
  background-image: url('../public/images/community.svg');
}

.job {
  background-image: url('../public/images/job.svg');
}

.resource {
  background-image: url('../public/images/paper.svg');
}


.buttonStyle{
  background: #6746AE;
  border: none; 
  color: white; 
  padding: 15px 32px; 
  text-align: center;
  text-decoration: none; 
  display: inline-block;
  font-size: 3rem; 
  margin: 4px 2px; 
  cursor: pointer;
  
}

.btnStyle{
  background: #6746AE;
  border: none; 
  color: white; 
  padding: 15px 32px; 
  text-align: center;
  text-decoration: none; 
  display: inline-block;
  font-size: 3rem; 
  margin: 4px 2px; 
  cursor: pointer;
}

.how{
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

.lines {
  display: grid;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.tab1 {
  background-color: black;
  width: 700px; 
  height: 20px; 
  position: absolute;
  left: 0; 
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.tab2 {
  background-color: rgb(0, 0, 0);
  width: 500px; 
  height: 20px; 
  position: absolute;
  left: 50%;
  border-radius: 30px;
}

.tab3 {
  background-color: black;
  width: 300px;
  height: 20px; 
  position: absolute;
  right: 0; 
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.teamContainer{
  display: grid;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5rem;
  min-height: 740px;
  grid-template-columns: 1fr;
}

.teamGroup{
  display: grid;
  align-items: center;
  justify-content: center;
}

.ceoAurelia{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-family: 'Fredoka One';
}

.teamTitle{
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 15rem;
}

.socialMediaTeam{
  justify-self: normal;
}

.webDevTeam, .socialMediaTeam{
  font-size: 2rem;
  font-family: 'Fredoka One';
}

.companyTeams{
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 10rem;
}

.webDev{
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 1fr 1fr;
}

.devMembers{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.socialMediaManagers{
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  overflow-y: hidden;
  overflow-x: hidden;
}

.mediaRole{
  font-size: 16px;
  font-family: 'League Spartan', sans-serif;
  margin: 10px;
  text-align: center;
  justify-self: center;
}


.icon{
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
}

.userImage{
  border-radius: 50%;
  object-fit: cover;
}

.socialMembers{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.members{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.name{
  font-size: 1.8rem;
  font-family: 'Poppins';
  margin: 10px;
  text-align: center;

}

.role{
  font-size: 16px;
  font-family: 'League Spartan', sans-serif;
  margin: 10px;
}

.socialMedia{
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.ig, .linked{
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  padding-bottom: 12px;
}

.ig{
  background-image: url('../public/images/ig_logo.svg');
}

.linked{
  background-image: url('../public/images/linkedin_logo.svg');
}

.userImage {
  transition: transform 0.3s;
  margin-top: 10px;
}
.userImage:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

footer{
  background: #6746AE;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  
}

.logos{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;

}

.circle{
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

a{
  margin-top: 12px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.instagram, .youtube, .linkedIn, .mail{
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  padding-bottom: 12px;
}

.instagram{
  background-image: url('../public/images/Instagram_white.png');

}

.youtube{
  background-image: url('../public/images/Youtube_white.png');
}

.linkedIn{
  background-image: url('../public/images/Linkedin_white.png');
}

.mail{
  background-image: url('../public/images/Gmail_white.png');
  color: white;
}

.copyright{
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%; 
  margin-bottom: 0.4rem; 
}
  
.formHeading {
  font-size: 2.2rem;
  color: black;
  text-align: center;
}
  
.suggestionForm {
  background: white;
  border: solid thick black;
  border-radius: 10px;
  width: 500px;
  padding-left: 2rem; 
  padding-right: 2rem; 
  padding-bottom: 2rem; 
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
  
.selectSubject {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

  
.nameSuggestion::placeholder,
.email::placeholder,
.message::placeholder {
  padding-left: 5px;
  font-size: 15px;
  font-family: 'Fredoka One', cursive;
  font-weight: 100;
}

.nameSuggestion, .email, .message{
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
  width: 100%;
  border: none;    
}

.nameSuggestion, .email{
  height: 50px;
}

.message {
  top: 0;
  left: 0;
  height: 150px;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  resize: none;
  font-family:'Work Sans', sans-serif;
  ;
}

input[type="text"] {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.suggestionName,
.suggestionEmail,
.suggestionSubjects,
.suggestionMessage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.2rem; 
  width: 100%; 
  margin-bottom: 0.5rem;     
}
  
.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
  
.subjectDropdown select {
  width: 250%;
  padding: 0.2rem;
  height: 40px;
  border-radius: 5px;
  border: none;
}
  
input.message::placeholder {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(0); 
  padding-top: 0.5rem; 
  font-size: 14px; 
}


.selectOption{
  font-size: 14px;
  font-family: 'Fredoka One', cursive;
  background-color: rgb(243, 243, 243);
}

.submitButton{
  padding-top: 10px;
}

.submit{
  height: 50px;
  width: 100px;
  font-size: 16px;
  font-family: 'Fredoka One';
  background-color: #5A068D;
  color: white;
  border-radius: 10px;
}

.submit:hover{
  transform: scale(1.1);
  cursor: pointer;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.loader {
  font-size: 20px;
  color: rgb(255, 255, 255);
  text-align: center;
}

.modal.success-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tick {
  font-size: 50px;
  color: green;
}

.success-modal p {
  font-size: 18px;
  margin-top: 10px;
}

.input-error {
  border: 2px solid red;
  outline: none;
}







/* Responsive Styles */
@media (min-width: 993px) and (max-width: 1500px) {
  .navigation ul li {
    font-size: 1.5rem;
  }
  
  .welcome, .bits, .byteQueens, .title, .paragraph {
    font-size: 6rem;
  }
  
  .applyButton {
    font-size: 2.5rem;
    width: 300px;
    height: 80px;
  }
  
  .contactContainer {
    font-size: 3rem;
  }
  
  .question {
    font-size: 5rem;
  }
  
  .answer, .ans {
    font-size: 1.8rem;
  }
  
  .icon {
    width: 150px;
    height: 150px;
  }

  main.aboutus{
    height: fit-content;
    overflow-x: hidden;
  }

  .aboutTitle{
    font-size: 3rem;
  }

  .aboutContainer{
    display: grid;
    align-items: center;
    justify-content: center;
    column-gap: 10rem;
    grid-template-columns: 1fr 1fr;
  }

  .aboutDetail{
    max-width: 100%;
    padding-left: 0px;
    margin-left: -8rem;
    margin-right: 5rem;
  }

  .ceoCard{
    width: 90%;
  }

  .applyMain{
    height: fit-content;
  }

  .applyButton{
    margin-bottom: 10px;
  }

  main.suggestion{
    height: fit-content;
  }

  .suggestionForm{
    margin: 10px;
  }


}

@media (min-width: 768px) and (max-width: 992px) {
  .navigation ul li {
    font-size: 1.2rem;
  }
  
  .welcome, .bits, .byteQueens, .title, .paragraph {
    font-size: 4rem;
  }
  
  .applyButton {
    font-size: 2rem;
    width: 250px;
    height: 60px;
  }
  
  .contactContainer {
    font-size: 2rem;
  }
  
  .question {
    font-size: 4rem;
  }
  
  .answer, .ans {
    font-size: 1.6rem;
  }
  
  .bitContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    text-align: center;
    margin-top: 70px;
    margin-left: -10px;
  }

  .teamContainer {
    flex-direction: column;
    gap: 2rem;
    margin-top: 100px; 
    margin-bottom: 50px;
    padding: 30px;
  }
  
  .icon {
    width: 120px;
    height: 120px;
  }

  main.aboutus{
    height: fit-content;
    overflow-x: hidden;
  }

  .aboutTitle{
    font-size: 3rem;
  }

  .aboutContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5rem;
    flex-direction: column;
  }

  .aboutDetail{
    max-width: 90%;
    padding-left: 0px;
    margin-left: -5rem;
    margin-right: 2.5rem;
  }

  .ceoCard{
    width: 90%;
  }
}



@media (max-width: 768px) {
  .navigation ul {
    flex-direction: column;
    padding: 10px;
  }

  .navigation ul li {
    font-size: 1rem;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .welcome, .bits, .byteQueens, .title, .paragraph {
    font-size: 3rem;
  }

  .applyButton {
    font-size: 1.5rem;
    width: 200px;
    height: 50px;
  }

  .contactContainer {
    font-size: 1.5rem;
  }

  .question {
    font-size: 3rem;
  }

  .answer, .ans {
    font-size: 1.4rem;
  }

  .bitContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    text-align: center;
    margin-top: 70px;
    margin-left: -30px;
  }

  .teamContainer {
    flex-direction: column;
    gap: 2rem;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .icon {
    width: 100px;
    height: 100px;
  }

  .navigation {
    position: relative;
    z-index: 1000;
  }

  .buttonStyle {
    background: #6746AE;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 2rem;
    margin: 4px 2px;
    cursor: pointer;
    z-index: 1001;

  }

  .buttonStyle:hover {
    background: #5A068D;
  }

  .navigation ul {
    background-color: #6746AE;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 60px;
    right: 0;
    width: 200px;
    max-height: 70vh;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .navigation ul li {
    padding: 10px 20px;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    width: 100%;
  }

  .navigation ul li:hover {
    background-color: #5A068D;
    width: 80%;

  }

  .link {
    text-decoration: none;
    width: 100%;
    display: block;
  }

  .resLink {
    color: white;
    display: block;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid white; 
  }

  .resLink:hover {
    color: #dcdcdc;
  }

  .aboutContainer{
    column-gap: 2rem;
  }

  .suggestionForm{
    width: 80%;
    margin:10px;
  }
}

@media (min-width: 578px) and (max-width: 700px){

  main.aboutus{
    height: auto;


  }

  .aboutContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .aboutDetail{
    padding-left: 0px;
    max-width: 400px;
    margin-left: -2rem;
  }

  .handshake, .trophy, .community, .job, .resource {
    height: 50px;
    width: 50px;
  }

  .details{
    gap: 0rem;
  }

  .companyTeams{
    gap: 0rem;
  }

  section.aboutTeam{
    overflow-x: hidden;
  }

  .suggestionForm {
    width: auto;
  }
  .subjectDropdown select {
    width: 100%;
  }

  .suggestionForm{
    width: 80%;
    margin:10px;
  }
   
}

@media (max-width: 577px) {
  
  .teamContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: auto;
    padding: 0px;
    overflow-x: hidden;
  }


  .team-member {
    width: 40%; 
    margin: 0.5rem 0; 
  }

  .companyTeams{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -4rem;
  }

  .name, .role{
    font-size: 1.1rem;
  }

  .teamTitle{
    visibility:hidden;
    width: 0px;

  }

  .teamTitle>span{
    font-size: 1rem;
  }

  .webDev, .socialMediaManagers{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .userImage{
    width: 600px;
    height: 600px;
    border-radius: 50%;
    object-fit: cover;
  }

  .navigation ul {
    flex-direction: column;
    padding: 5px;
  }
  
  .navigation ul li {
    font-size: 0.8rem;
    margin-right: 0;
    margin-bottom: 5px;
  }

  .bookmark{
    height: 230px;  
  }
  
  .welcome, .byteQueens, .title, .paragraph {
    font-size: 2rem;
  }
  
  .paragraph{
    padding-right: 20px;
  }

  .bitContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    text-align: center;
    margin-top: 70px;
    margin-left: -30px;
  }

  .applyButton {
    font-size: 1rem;
    width: 150px;
    height: 50px;
  }
  
  .contactContainer {
    font-size: 1rem;
  }
  
  .question {
    font-size: 2rem;
  }
  
  .answer, .ans {
    font-size: 1rem;
  }
  
  .aboutTitle{
    font-size: 1.7rem;
    margin: 0px;
  }

  main.aboutus {
    height: 100%;
  }

  .aboutContainer{ 
    grid-template-columns: 1fr; 
    row-gap: 2rem; 
    width: 100%;
  } 
  
  .ceoCard{ 
    width: 100%; 
  } 

  .aboutDetail{ 
    padding: 0px; 
    width: 84%; 
    text-align: center; 
  } 

  .question{
    font-size: 2.5rem; 
  } 
  
  .answer{ 
    font-size: 1.2rem; 
    padding: 15px;
  } 
  
  .details{ 
    flex-direction: column; 
    gap: 1rem; 
  } 
  
  .mentorship, .competitions, .communities, .jobs, .resources{
    width: 100%; 
  }
  
  .icon {
    width: 100px;
    height: 100px;
  }

  .suggestionForm{
    width: 80%;
    height: 90%;
    
  }

  .subjectDropdown select{
    width: 175%;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .youtube {
    transform: rotate(-90deg); /* Corrects the rotation for iPhones */
    width: 80px;
    height: 80px;
    margin-left: 17px;
  }
  .circleYoutube{
    width: 80px;
    height: 80px;
    padding-bottom: 10px;
  }
}
